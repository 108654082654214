<template>
  <v-container>
    <div class="d-flex flex-column align-center my-5">
        <v-img
          :src="require('@/assets/logo_spm_small.png')"
          max-width="10%"
          max-height="10%"
        />
      <h1 class="my-3 mainTitleStyle2">{{ $t("app-title") }}</h1>
    </div>
    <v-card max-width="600" class="mx-auto" :loading="loading">
      <v-tabs v-model="tab" fixed-tabs>
        <v-tab>
          {{ $t("login-as-user") }}
        </v-tab>
        <v-tab>
          {{ $t("login-as-technician") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-form @submit.prevent="loginUser" :disabled="loading">
              <v-card-text>
                <v-text-field
                  :label="$t('box-address')"
                  filled
                  name="box-address"
                  type="url"
                  v-model="user.boxUrl"
                  hide-details
                ></v-text-field>
              </v-card-text>
              <v-list v-if="boxUrlHistory.length">
                <v-list-item dense>
                  <h4>
                    {{ $t("history") }}
                  </h4>
                </v-list-item>

                <v-list-item
                  v-for="(url, i) in boxUrlHistory"
                  :key="url"
                  @click="
                    user.boxUrl = url;
                  "
                >
                  <v-list-item-title>
                    {{ url }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon @click="boxUrlHistory.splice(i, 1)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="$t('username')"
                      filled
                      autocomplete="username"
                      v-model="user.HumanUserID"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :label="$t('password')"
                      filled
                      type="password"
                      autocomplete="current-password"
                      v-model="user.Password"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text type="submit" :loading="loading">
                  {{ $t("login") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-form @submit.prevent="loginTech" :disabled="loading">
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="$t('username')"
                      filled
                      autocomplete="username"
                      v-model="tech.UserID"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :label="$t('password')"
                      filled
                      type="password"
                      autocomplete="current-password"
                      v-model="tech.UserSecret"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text type="submit" :loading="loading">
                  {{ $t("login") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import saveState from "vue-save-state";
export default {
  name: "Login",
  mixins: [saveState],
  data: () => ({
    tab: null,
    loading: false,
    tech: { type: "tech" },
    user: {
      type: "user",
      boxUrl: null
    },
    boxUrlHistory: []
  }),
  mounted() {
    // https://mloth55pt8.execute-api.eu-central-1.amazonaws.com/Prod/
    if (
      this.$store.state.user &&
      this.$store.state.user.type === "user" &&
      this.$store.state.user.boxUrl
    ) {
      this.user.boxUrl = this.$store.state.user.boxUrl;
      //this.loginUser();
    }
    //console.log(this.$store.state.user);
  },
  methods: {
    async loginTech() {
      if ((!this.tech.UserID) || (!this.tech.UserSecret))
      {
        if (!this.tech.UserID)
        {
          alert(this.$t("missing-user"));
        }
        else if (!this.tech.UserSecret)
        {
          alert(this.$t("missing-password"));
        }
        
        return false;
      }

      this.loading = true;
      this.$store.commit("setUser", this.tech);
      
      try {
        await this.$store.dispatch("checkTechRole", this.tech);
        await this.$store.dispatch("fetchRecords");
        this.go();
      } catch (error) {
        if (error.message.includes("code 403"))
        {
          alert(this.$t("invalid-user-or-password"));
        }
        else if (error.message.includes("wrong-role"))
        {
          alert(this.$t("wrong-role"));
        }
        else
        {
          alert(error);
        }
        this.$store.commit("setUser", null);
      }
      this.loading = false;
    },
    async loginUser() {
      if ((!this.user.HumanUserID) || (!this.user.Password))
      {
        if (!this.user.HumanUserID)
        {
          alert(this.$t("missing-user"));
        }
        else if (!this.user.Password)
        {
          alert(this.$t("missing-password"));
        }
        
        return false;
      }
      
      if (!this.user.boxUrl.startsWith("http"))
      {
        const re1 = /spm[0-9]+/;
        const re2 = /SPM[0-9]+/;
        
        if (re1.test(this.user.boxUrl))
        {
          this.user.boxUrl = "https://" + this.user.boxUrl + ".spmpodologicbox.com";
        }
        else if (re2.test(this.user.boxUrl))
        {
          this.user.boxUrl = "https://" + this.user.boxUrl.toLowerCase() + ".spmpodologicbox.com";
        }
        else
        {
          this.user.boxUrl = "https://" + this.user.boxUrl;
        }
      }
      
      console.log("Login:", this.user);
      
      this.loading = true;
      
      this.$store.commit("setUser", this.user);
      try {
        await this.$store.dispatch("getBoxCredentials");
        await this.$store.dispatch("fetchRecords");
        if (!this.boxUrlHistory.includes(this.user.boxUrl))
          this.boxUrlHistory.push(this.user.boxUrl);
        window.setTimeout(() => {
          this.go();
        }, 0);
      } catch (error) {
        console.error(error);
        alert(this.$t(error.message));
        this.$store.commit("setUser", null);
      }
      this.loading = false;
    },
    go() {
      if (this.$route.query.redirect)
        this.$router.push(this.$route.query.redirect);
      else this.$router.push({ name: "Home" });
    },
    getSaveStateConfig() {
      return {
        cacheKey: "loginView",
        saveProperties: ["boxUrlHistory"]
      };
    }
  }
};
</script>

<style>
.mainTitleStyle2 {
  text-align: center;
  color: #007000;
  font-family: Cambria;
}

</style>
